import React from "react";

function Privacidad() {
  window.scrollTo(0, 0);
  return (
    <div className="infoPages">
      <h2>Política de Privacidad.</h2>
      <p>
        Cuando realices un pedido a través del Sitio, mantendremos tu
        Información de Pedido para nuestros registros a menos que y hasta que
        nos solicites eliminar esta información.
      </p>
    </div>
  );
}

export default Privacidad;
