import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Cart from "./Cart";
import LOGO from "../images/logo.png";
import Button from "react-bootstrap/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function OffCanvasExample({
  tags,
  names,
  products,
  value,
  setValue,
  name,
  ...props
}) {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    handleClose();
  }, [window.location.href]);

  return (
    <>
      <i
        style={{ cursor: "pointer", width: "49.5px" }}
        onClick={handleShow}
        class="fa-solid fa-bars burgerMenu navAnimator"
      ></i>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>ATROCITY</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* <div className="d-flex justify-content-center">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
                if (newValue !== null) {
                  const thisProduct = products.filter(
                    (product) => product.name == newValue
                  )[0];
                  navigate(`/product/${thisProduct.slug}`);
                }
              }}
              options={names}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Buscar" />}
              style={{
                height: "80px",
                width: "90%",
                display: "flex",
                alignItems: "center",
              }}
            />
          </div> */}
          <ul>
            <li>
              <Link className="dropdown-item p-3" to={`/`}>
                HOME
              </Link>
            </li>
            {tags &&
              tags.map((tag) => (
                <li key={tag.id}>
                  <Link className="dropdown-item p-3" to={`/tag/${tag.slug}`}>
                    {tag.name}
                  </Link>
                </li>
              ))}
            <hr></hr>
            <li>
              <Link className="dropdown-item p-3" to={`/sobre-nosotros`}>
                Sobre Nosotros
              </Link>
            </li>
            <li>
              <Link className="dropdown-item p-3" to={`/cambios-devoluciones`}>
                Política de Cambios y Devoluciones
              </Link>
            </li>
            <li>
              <Link className="dropdown-item p-3" to={`/envios`}>
                Política de Envío
              </Link>
            </li>
            <li>
              <Link className="dropdown-item p-3" to={`/privacidad`}>
                Política de Privacidad
              </Link>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function Nav() {
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [tags, setTags] = useState();
  const [value, setValue] = useState();
  const [products, setProducts] = useState();
  const [names, setNames] = useState();
  useEffect(() => {
    const getTags = async () => {
      const result = await axios.get(`${process.env.REACT_APP_API}/api/tags`);
      setTags(result.data);
    };
    const getAllProducts = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API}/api/products/all`
      );
      let productos = [];
      for (let index = 0; index < result.data.length; index++) {
        productos.push(result.data[index].name);
      }
      setNames(productos);
      setProducts(result.data);
    };
    getTags();
    getAllProducts();
  }, []);

  return (
    <div className="center" id="Nav">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center p-3">
          <div className="d-flex justify-content-start align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={LOGO}
                className="navitem logo navAnimator"
                onClick={() => navigate("/")}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <div className="position-relative zoomSearcher">
              {/* {showSearch && (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue !== null) {
                      const thisProduct = products.filter(
                        (product) => product.name == newValue
                      )[0];
                      navigate(`/product/${thisProduct.slug}`);
                    }
                  }}
                  options={names}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Buscar" />
                  )}
                  className="navSearcher"
                />
              )}
              <i
                style={{ cursor: "pointer" }}
                onClick={() => setShowSearch(!showSearch)}
                class="fa-solid fa-magnifying-glass"
              ></i> */}
            </div>
            <OffCanvasExample
              tags={tags}
              names={names}
              products={products}
              setValue={setValue}
              value={value}
              placement={"end"}
              name={"end"}
            />
            <Cart placement={"end"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
