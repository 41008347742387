import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function CreateOrder() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let newTotal = 0;
    reduxCart.map((item) => {
      newTotal = Number(item.price) + Number(newTotal);
    });
    setTotal(newTotal);
  }, []);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [id, setId] = useState();
  const [depart, setDepart] = useState();
  const [city, setCity] = useState();
  const [address, setAddress] = useState();
  const [deliverInfo, setDeliverInfo] = useState();
  const [status, setStatus] = useState();
  const [products, setProducts] = useState();

  const [total, setTotal] = useState();
  const reduxCart = useSelector((state) => state.cart);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API}/api/create/order`, {
        name: `${name} - CI: ${id}`,
        email,
        phone,
        depart,
        city,
        address,
        deliverInfo,
        status: "NOT_PAYED",
        products: JSON.stringify(reduxCart),
        total,
      })
      .then((result) => {
        navigate(`/order/${result.data.id}`);
      });
  };

  return (
    <div id="CreateOrder" className="d-flex justify-content-center">
      <div className="container">
        <div className="row">
          <div
            className="col-md-6 p-3"
            style={{ borderRight: "1px solid black" }}
          >
            <h3>Productos Seleccionados:</h3>
            {reduxCart.map((item) => (
              <div className="m-3 row">
                <div className="col-6">
                  <p>
                    {item.name} - {item.size}
                  </p>
                </div>
                <div className="col-6">
                  <p className="d-flex justify-content-end">
                    + $
                    {item.price
                      .split(".")[0]
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </p>
                </div>
              </div>
            ))}
            <hr />
            <div className="m-3 row">
              <div className="col-6">
                <p>TOTAL</p>
              </div>
              <div className="col-6">
                <p className="d-flex justify-content-end">
                  {" "}
                  <strong>${total}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-3">
            <h3>Datos de Comprador:</h3>
            <form
              method="post"
              onSubmit={(e) => handleSubmit(e)}
              className="buyerForm"
            >
              <input
                onChange={(e) => setName(e.target.value)}
                required
                type="text"
                placeholder="Nombre Completo"
                name="name"
              />
              <input
                onChange={(e) => setEmail(e.target.value)}
                required
                type="email"
                placeholder="Email"
                name="email"
              />
              <input
                onChange={(e) => setPhone(e.target.value)}
                required
                type="text"
                placeholder="Celular"
                name="phone"
              />
              <input
                onChange={(e) => setId(e.target.value)}
                required
                type="text"
                placeholder="Cedula"
                name="id"
              />
              <input
                onChange={(e) => setDepart(e.target.value)}
                required
                type="text"
                placeholder="Departamento"
                name="depart"
              />
              <input
                onChange={(e) => setCity(e.target.value)}
                required
                type="text"
                placeholder="Ciudad"
                name="city"
              />
              <input
                onChange={(e) => setAddress(e.target.value)}
                required
                type="text"
                placeholder="Direccion"
                name="address"
              />
              <input
                onChange={(e) => setDeliverInfo(e.target.value)}
                required
                type="text"
                placeholder="Intrucciones"
                name="deliverInfo"
              />
              <button type="submit">Continuar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateOrder;
