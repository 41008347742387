import { Route, Routes } from "react-router-dom";
import "./App.css";
import Contact from "./components/Contact";
import CreateOrder from "./components/CreateOrder";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import Nav from "./components/Nav";
import OrderInfo from "./components/OrderInfo";
import Product from "./components/Product";
import ProductsByTag from "./components/ProductsByTag";
import CamDev from "./components/CamDev";
import Encargos from "./components/Encargos";
import Envios from "./components/Envios";
import SobreNosotros from "./components/SobreNosotros";
import Privacidad from "./components/Privacidad";
import Manugarderes from "./components/Manugarderes";
import LOGO from "./images/logo.png";

function App() {
  return (
    <div>
      {/* <div className="soon">
        <img style={{ width: "50vw" }} src={LOGO} />
        <h3>COMING - SOON</h3>
      </div> */}
      {/* <div className="navNoti">
        Envíos a todo el país, pagá con el método de pago que prefieras.
      </div> */}
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tag/:tagSlug" element={<ProductsByTag />} />
        <Route path="/product/:productSlug" element={<Product />} />
        <Route path="/create-order" element={<CreateOrder />} />
        <Route path="/order/:id" element={<OrderInfo />} />
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/cambios-devoluciones" element={<CamDev />} />
        {/* <Route path="/encargos" element={<Encargos />} /> */}
        <Route path="/envios" element={<Envios />} />
        <Route path="/privacidad" element={<Privacidad />} />
        <Route path="/sobre-nosotros" element={<SobreNosotros />} />
        <Route path="*" element={<h1>404</h1>} />
      </Routes>
      <Footer />
      <Manugarderes />
    </div>
  );
}

export default App;
