import axios from "axios";
import React, { useEffect, useState } from "react";
import Tag from "./Tag";

function Tags() {
  useEffect(() => {
    const getTags = async () => {
      const result = await axios.get(`${process.env.REACT_APP_API}/api/tags`);
      setTags(result.data);
    };
    getTags();
  }, []);

  const [tags, setTags] = useState();

  return (
    <div id="Tags">
      <div style={{ minHeight: "100vh" }} className="center">
        <div className="container">
          <div className="row justify-content-center">
            {tags &&
              tags.map((tag) => (
                <Tag key={tag.id} tag={tag} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tags;
