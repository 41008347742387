import React from "react";

function SobreNosotros() {
  window.scrollTo(0, 0);
  return (
    <div className="infoPages">
      <h2>Sobre nosotros.</h2>
      <p>
        Atrocity Clothing es una tienda Streetwear fundada en Uruguay desde
        Julio de 2022. Vendemos prendas únicas y exclusivas.
      </p>
    </div>
  );
}

export default SobreNosotros;
