import React from "react";

function CamDev() {
  window.scrollTo(0, 0);
  return (
    <div className="infoPages">
      <h2>Políticas de cambios y devoluciones.</h2>
      <p>
        Se podrá hacer el cambio de talle o devolución del dinero dentro de los
        7 días posteriores a la compra del producto, siempre que estén nuevas y
        en su empaque original, para pedir el cambio o devolución comuníquese
        por WhatsApp.
      </p>
    </div>
  );
}

export default CamDev;
