import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../redux/cartSlice";
import Alert from "react-bootstrap/Alert";

function Product() {
  const reduxCart = useSelector((state) => state.cart);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    const getProductBySlug = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API}/api/products/${productSlug}`
      );
      setProduct(result.data);
      let newVariants = result.data.variants.map((variant) => {
        return variant;
      });
      setVariants(newVariants);
    };
    getProductBySlug();
  }, [window.location.href]);
  const { productSlug } = useParams();
  const [product, setProduct] = useState();
  const [added, setAdded] = useState(false);
  const [sizeSelected, setSizeSelected] = useState();
  const [variants, setVariants] = useState();
  return (
    <div id="Product">
      {product ? (
        <div className="center">
          <div className="container">
            <div className="row row-cols-md-2 row-cols-1">
              <div className="col">
                <Carousel>
                  <div>
                    <img src={product.path} />
                  </div>
                  {product.images &&
                    product.images.map((image) => (
                      <div key={image.id}>
                        <img src={image.url} />
                      </div>
                    ))}
                </Carousel>
              </div>
              <div className="col">
                <div className="p-5 productInfo d-flex flex-column">
                  <h2 className="mb-4">{product.name.split("*-*")}</h2>
                  <h5
                    style={
                      error ? { color: "red", textDecoration: "underline" } : {}
                    }
                  >
                    Talles Disponibles:
                  </h5>
                  {variants && (
                    <select
                      onChange={(e) => {
                        setError(false);
                        setSizeSelected(e.target.value);
                      }}
                      id="SizeSelection"
                    >
                      <option disabled selected>
                        Elegí un talle
                      </option>
                      {variants
                        .filter((item) => item.stock > 0)
                        .map((variant) => (
                          <option key={variant.id} value={variant.size}>
                            {variant.size}
                          </option>
                        ))}
                    </select>
                    // <div className="d-flex justify-content-around my-4">
                    //   {variants.map((variant) => (
                    //     <div
                    //       key={variant.id}
                    //       onClick={() => {
                    //         setError(false);
                    //         setSizeSelected(variant.size);
                    //       }}
                    //       style={
                    //         sizeSelected === variant.size
                    //           ? { backgroundColor: "black", color: "white" }
                    //           : {}
                    //       }
                    //       className={"size"}
                    //     >
                    //       <span>{variant.size}</span>
                    //     </div>
                    //   ))}
                    // </div>
                  )}
                  {added && (
                    <Alert className="p-3" variant={"success"}>
                      Producto Agregado al carrito!
                    </Alert>
                  )}
                  {!added && (
                    <button
                      onClick={() => {
                        if (sizeSelected) {
                          setAdded(true);
                          localStorage.setItem(
                            "cart",
                            JSON.stringify([
                              ...reduxCart,
                              {
                                productId: product.id,
                                name: product.name.split("*-*"),
                                price: product.price,
                                path: product.path,
                                cartId: Math.random(),
                                size: sizeSelected,
                              },
                            ])
                          );
                          dispatch(
                            setCart([
                              ...reduxCart,
                              {
                                productId: product.id,
                                name: product.name.split("*-*"),
                                price: product.price,
                                path: product.path,
                                cartId: Math.random(),
                                size: sizeSelected,
                              },
                            ])
                          );
                          setTimeout(() => {
                            setAdded(false);
                          }, 3000);
                        } else {
                          setError(true);
                        }
                      }}
                      className="addToCartButton d-flex justify-content-between align-items-center mb-4"
                    >
                      <span>Agregar al Carrito</span>
                      <span>
                        {product.offer && (
                          <b
                            className="m-2"
                            style={{
                              textDecoration: "line-through",
                            }}
                          >
                            $
                            {product.offer
                              .split(".")[0]
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </b>
                        )}
                        <b>
                          $
                          {product.price
                            .split(".")[0]
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </b>
                      </span>
                    </button>
                  )}
                  {product.desc && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product.desc,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
  );
}

export default Product;
