import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";

function Tag({ tag }) {
  const navigate = useNavigate();
  const [onHover, setOnHover] = useState();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      data-aos="fade-up"
      onMouseEnter={() => setOnHover(tag.id)}
      onMouseLeave={() => setOnHover()}
      onClick={() => navigate(`/tag/${tag.slug}`)}
      className="tagBox position-relative center col-6 mt-3"
    >
      <img
        style={onHover === tag.id ? { filter: "blur(5px)" } : {}}
        src={tag.url}
      />
      <div className="blackOut"></div>
      <h1 className="tagTitle position-absolute">{tag.name.toUpperCase()}</h1>
    </div>
  );
}

export default Tag;
