import React from "react";
import Portrait from "../../images/banner.jpeg";
import LOGO from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  return (
    <div id="Header">
      <div className="headerBlackOut">
        <div className="d-flex justify-content-center align-items-center">
          {/* <img
            style={{ width: "50vw" }}
            src={LOGO}
            onClick={() => navigate("/")}
          /> */}
        </div>
      </div>
      <img className="headerImg" src={Portrait} />
    </div>
  );
}

export default Header;
