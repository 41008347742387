import React from "react";
import Consignation from "./Consignations";
import DropProducts from "./DropProducts";
import Extras from "./Extras";
import Header from "./Header";
import ProProducts from "./ProProducts";
import NoProProducts from "./NoProProducts";
import Tags from "./Tags/Tags";

function Home() {
  return (
    <div style={{ backgroundColor: "black", color: "white" }}>
      <Header />
      <div style={{ height: "100vh" }}></div>
      {/* <DropProducts /> */}
      <ProProducts />
      <NoProProducts />
      <Tags />
      {/* <Consignation /> */}
      <Extras />
    </div>
  );
}

export default Home;
