import React, { useEffect } from "react";
import IZQ from "../../extra-images/extra1.jpeg";
import MEDIO from "../../extra-images/extra2.jpeg";
import DER from "../../extra-images/extra3.jpeg";
import AOS from "aos";

function Extras() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div id="Extras" className="mt-5 d-flex justify-content-center py-5">
      <div className="container">
        {/* <h2 className="text-center mb-4">CLIENTES DEL PLUG</h2> */}
        <div className="row">
          <div data-aos="fade-up" className="col-md-4">
            <img src={IZQ} />
          </div>
          <div data-aos="fade-up" className="col-md-4">
            <img src={MEDIO} />
          </div>
          <div data-aos="fade-up" className="col-md-4">
            <img src={DER} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Extras;
