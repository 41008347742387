import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function ProductCard({ product }) {
  const navigate = useNavigate();
  const [onHover, setOnHover] = useState(false);

  return (
    <div
      onClick={() => {
        if (product.variants.length > 0) {
          navigate(`/product/${product.slug}`);
        }
      }}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      id="ProductCard"
      style={product.variants.length === 0 ? { cursor: "auto" } : {}}
      className="col-md-3 col-6 productBox center flex-column mt-4"
    >
      <img
        src={
          onHover
            ? product.images.length > 0
              ? product.variants.length > 0
                ? product.images[0].url
                : product.path
              : product.path
            : product.path
        }
      />
      <div className="info">
        <p>{product.name.split("*-*")}</p>
        <p>
          {product.offer && (
            <span
              className="m-2"
              style={{
                textDecoration: "line-through",
              }}
            >
              U$
              {product.offer
                .split(".")[0]
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            </span>
          )}
          <span>
            U$
            {product.price.split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
          </span>
        </p>
        {product.variants.filter((item) => item.stock > 0).length === 0 && (
          <div className="blackOut">AGOTADO</div>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
