import React from "react";
import ContactImg from "../images/contactImg.jpeg";

function Contact() {
  window.scrollTo(0, 0);
  return (
    <div id="Contact">
      <div className="contactImg">
        <img src={ContactImg} />
      </div>
      <div className="contactForm">
        <h1>Contactate</h1>
        <p className="mt-3">
          ¿Tenes alguna consulta, queres dejar tus productos en consignación o
          realizar un encargo? Contactate con nosotros.
        </p>
        <p className="mt-3">test.uy@gmail.com</p>
        <form>
          <input type="text" placeholder="Nombre" />
          <input type="email" placeholder="Email" />
          <input type="text" placeholder="Asunto" />
          <textarea placeholder="Mensaje"></textarea>
          <button>ENVIAR MENSAJE</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
