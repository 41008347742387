import React, { useState } from "react";
import Modo1 from "../images/modo_de_pago_1.png";
import Modo2 from "../images/modo_de_pago_2.png";
import LockIcon from "@mui/icons-material/Lock";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Footer() {
  return (
    <div id="footer" className="d-flex justify-content-center">
      <div className="container">
        <div className="row px-4">
          <div className="col-md-3 mt-3">
            <h2>CONTACTO</h2>
            <p style={{ display: "flex", alignItems: "center" }}>
              <a
                target="_blank"
                style={{ color: "white", textDecoration: "none" }}
                href="https://www.instagram.com/atrocity.stw"
              >
                <InstagramIcon /> atrocity.stw
              </a>
            </p>
            <p style={{ display: "flex", alignItems: "center" }}>
              <a
                target="_blank"
                style={{ color: "white", textDecoration: "none" }}
                href="https://api.whatsapp.com/send?phone=59894687019"
              >
                <WhatsAppIcon /> +598 94 687 019
              </a>
            </p>
            <p className="point">
              <Link to="/sobre-nosotros">Sobre Nosotros</Link>
            </p>
          </div>
          <div className="col-md-3 mt-3 d-flex justify-content-center align-items-center flex-column">
            <h2 className="text-center mb-2">METODOS DE ENVIO</h2>
            <img
              style={{
                width: "50%",
                height: "100px",
                objectFit: "contain",
              }}
              src={
                "https://carmeloya.com/wp-content/uploads/2017/01/DAC-300x300.jpg"
              }
            />
          </div>
          <div className="col-md-3 mt-3 d-flex justify-content-center align-items-center flex-column">
            <h2 className="text-center mb-2">METODOS DE PAGO</h2>
            <img
              style={{
                width: "50%",
                height: "100px",
                objectFit: "contain",
              }}
              src={
                "https://www.siniestro.net/images/Novedades/Te-conviene-aceptar-pagos-con-Mercadopago-en-tu-tienda.jpg"
              }
            />
          </div>
          <div className="col-md-3 mt-3 d-flex flex-column align-items-end">
            <h2>POLÍTICA</h2>
            <p className="point">
              <Link to="/cambios-devoluciones">
                Política de Cambios y Devoluciones
              </Link>
            </p>
            <p className="point">
              <Link to="/envios">Política de Envíos</Link>
            </p>
            <p className="point">
              <Link to="/privacidad">Política de Privacidad</Link>
            </p>
          </div>
          {/* <div className="col-md-3 mt-4">
            <h2>POR QUÉ Atrocity?</h2>
            <p style={{ display: "flex", alignItems: "center" }}>
              <LocalShippingIcon className="iconFromFooter" /> Envíos a todo el
              país
            </p>
            <p style={{ display: "flex", alignItems: "center" }}>
              <LockIcon className="iconFromFooter" /> Sitio Seguro
            </p>
            <p style={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon className="iconFromFooter" /> Garantías de
              Autenticidad
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
