import React from "react";

function Manugarderes() {
  return (
    <div style={{ backgroundColor: "black" }}>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "50px 0" }}
      >
        <a target="_blank" href="https://manugarderes.com/">
          <img
            style={{ width: "200px", height: "200px" }}
            src="https://i.ibb.co/RBmrpY7/MANU-GARDERES.png"
            alt=""
          />
        </a>
      </div>
    </div>
  );
}

export default Manugarderes;
