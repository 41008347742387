import React from "react";

function Envios() {
  window.scrollTo(0, 0);
  return (
    <div className="infoPages">
      <h2>Política de envíos.</h2>
      <p>
        Los envíos se realizaron únicamente y exclusivamente por DAC y el costo
        del envío será abonado por la persona al momento de recibir el artículo.
      </p>
    </div>
  );
}

export default Envios;
