import axios from "axios";
import React, { useEffect, useState } from "react";
import ProductCard from "../ProductCard";

function DropProducts() {
  useEffect(() => {
    const getProductsByDrop = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API}/api/products/drop`
      );
      setProducts(result.data);
    };
    getProductsByDrop();
  }, []);

  const [products, setProducts] = useState();

  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column my-5"
      id="ProProducts"
    >
      <h2 style={{ textAlign: "center" }}>DROP DE ESSENTIALS, DREW & STÜSSY</h2>
      <div className="center">
        <div className="container">
          <div className="row justify-content-center">
            {products &&
              products.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DropProducts;
